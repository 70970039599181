<template>
  <main class="materials-styles">
    <div class="horiz-container">
      <div class="flex items-center gap-3 text-light mb-10 breadcrumbs">
        <NuxtLink :to="{name: 'materials'}" class="link-subnav active">Мои материалы</NuxtLink>
        <span class="icon-chevron-right"></span>
        <NuxtLink :to="$route" class="link-subnav">
          {{ form.id ? `Редактирование д/з: "${form.name}"` : 'Создание д/з' }}
        </NuxtLink>
      </div>
      <form @submit.prevent="submit">
        <div class="school-edit-card mb-6">
          <BaseInput v-model="form.name" :error="errors.name"
                     type="text" class="input-text input-h2 bg-transparent mb-10" placeholder="Введите название"/>
          <BaseFileUploader v-model="form.file" :error="errors.file"
                            type="file"
                            class="input-row mb-10" label="Файл для д/з"/>
          <ClientOnly><ContentEditor :editor-data="form.description" @init="editor = $event" class="mb-10 py-16"/></ClientOnly>
          <small v-if="errors.description" class="form__field__caption form__field__caption--error">{{ errors.description }}</small>
        </div>
        <div class="flex justify-end gap-4 edit-actions">
          <button type="button" class="btn btn-secondary btn-fullwidth w-auto text-system-m p-4"
                  @click="remove" v-if="form.id">Удалить
          </button>
          <button type="submit" class="btn btn-primary btn-fullwidth w-auto text-system-m p-4">Сохранить</button>
        </div>
      </form>
    </div>
  </main>
  <ClientOnly><PromptModal @provide="prompt = $event"/></ClientOnly>
</template>

<script setup>
import BaseInput from '@/components/common/Input'
import useForm from '~/composables/useForm'
import { deleteLesson, getHomework, storeHomework, updateHomework } from '@/api/materials'
import ContentEditor from '@/components/common/ContentEditor'
import BaseFileUploader from '@/components/common/FileUploader'
import PromptModal from '@/components/common/PromptModal'

const prompt = ref(null)
const router = useRouter()
const route = useRoute()
const editor = ref(null)

const {
  form,
  errors,
  loading,
  submit
} = useForm(async data => {
  if (editor.value) data.description = await editor.value.save()
  return await (form.value.id
    ? updateHomework(route.params.lessonId, data)
    : storeHomework(route.params.sectionId, data))
}, () => {
  router.push({
    name: 'materials'
  })
})

useHead({
  title: computed(() => form.value?.id ? `Редактирование д/з - ${form.value?.name}` : 'Создание д/з')
})

async function remove () {
  const accept = await prompt.value({
    title: `Удалить урок ${form.value.name}`,
    description: 'Вы уверены, что хотите удалить этот урок? Это необратимое действие.'
  })
  if (accept) {
    await deleteLesson(route.params.lessonId)
    await router.replace({ name: 'materials' })
  }
}

const { data: homework } = await useAsyncData(async () => route.params.lessonId ? await getHomework(route.params.lessonId) : {})
if (!homework.value) throw createNotFoundError()

watchEffect(() => {
  const data = homework.value
  form.value = {
    ...data,
    description: data.homework?.description ? JSON.parse(data.homework.description) : undefined,
    file: data.homework?.file
  }
})
</script>

<style lang="scss">
@import "@/assets/css/materials";
</style>
